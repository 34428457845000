<template>
  <ul class="mainLinks hide-on-lg">
    <li>
      <router-link :to="$i18nRoute({name: 'brandDashboard'})">
        <svg-icon icon-id="compaign" icon-viewbox="0 0 14.788 13.238">
        </svg-icon>
        {{$t('Campaign')}}
      </router-link>
    </li>
    <li>
      <router-link :to="$i18nRoute({name: 'brandInfluencers'})">
        <svg-icon icon-id="Influencers" icon-viewbox="0 0 20.738 11.668">
        </svg-icon>
        {{$t('Influencers')}}
      </router-link>
    </li>
    <li v-if=showDeal>
      <router-link :to="$i18nRoute({name: 'brandDeals'})">
        <svg-icon icon-id="deal" icon-viewbox=" 0 0 14.788 13.238"> </svg-icon>
        {{$t('Deal')}}
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  data(){
    return{
      showDeal: false,
    };
  },
};
</script>

<style></style>
